<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-alert
        v-show="copyAlert"
        type="info"
        text
      >
        {{ msgtokencopiado }}
      </v-alert>
      <v-alert
        v-show="alertStatus"
        type="success"
        text
      >
        {{ msgAlertStatus }}
      </v-alert>
      <base-card>
        <v-card-text>
          <v-card-title>
            Listados Clientes FE
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="details"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.Estado`]="{ item }">
              <v-icon
                v-if="item.Estado === 'H'"
                color="yellow darken-2"
                right
                class="mr-2"
              >
                mdi-alpha-h-circle
              </v-icon>
              <v-icon
                v-else-if="item.Estado === 'P'"
                color="success darken-2"
                right
                class="mr-2"
              >
                mdi-alpha-p-circle
              </v-icon>

              <v-icon
                v-else
                color="info darken-2"
                right
                class="mr-2"
              >
                mdi-alpha-d-circle
              </v-icon>
            </template>
            <template v-slot:[`item.Activo`]="{ item }">
              <v-icon
                v-if="item.Activo === 1"
                color="success darken-2"
                right
                class="mr-2"
              >
                mdi-account-check
              </v-icon>
              <v-icon
                v-else
                color="warning darken-2"
                right
                class="mr-2"
              >
                mdi-account-cancel
              </v-icon>
            </template>
            <template v-slot:[`item.TokenEnterprise`]="{ item }">
              <v-icon
                v-clipboard:copy="item.TokenEnterprise"
                v-clipboard:success="onCopy"
                style="cursor:pointer;"
                color="info darken-3"
                class="mr-2"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <template v-slot:[`item.TokenPassword`]="{ item }">
              <v-icon
                v-clipboard:copy="item.TokenPassword"
                v-clipboard:success="onCopy"
                style="cursor:pointer;"
                color="info darken-3"
                class="mr-2"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <template v-slot:[`item.Nit`]="{ item }">
              <v-dialog
                  max-width="1000"
                  transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      color="info darken-3"
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-information-box
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                        dark
                        style="background-color:rgb(204 33 40);color:white !important"
                    >
                      DATOS FABRICANTE/DISTRIBUIDOR - CLIENTE [{{ item.NombreCompania }}]

                      <v-icon
                          style="margin-left: 460px !important;"
                          @click="dialog.value = false"
                      >mdi-close
                      </v-icon>

                    </v-toolbar>

                    <campo-client
                        :token_enterprise=item.TokenEnterprise
                        :token_password=item.TokenPassword
                        :fabricante=item.FabricanteSoftware
                        :nit=item.Nit
                        :razon_social=item.RazonSocialDistribuidor
                        :distribuidor=item.Distribuidor
                    />

                  </v-card>

                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.EmailDefaultFE`]="{ item }">
              <v-dialog
                v-model="dialog"
                max-width="500"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="warning darken-3"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <template v-slot:default="{ dialog }">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                       {{ item.NombreCompania }}

                      <v-icon
                        style="margin-left: auto;"
                        @click="dialog = false" 
                      >
                        mdi-close
                      </v-icon>
                    </v-toolbar>

                    <campo-client-email-facturacion
                      :token_enterprise=item.TokenEnterprise
                      :token_password=item.TokenPassword
                      :email=item.EmailDefaultFE
                    />

                  </v-card>

                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.ActivarInactivar`]="{ item }" >
              <v-icon
                v-if="item.Activo == 1"
                style="cursor:pointer;text-align-last: center;"
                color="red darken-3"
                class="mr-2"
                @click="activeInactiveClients(item)"
              >
                mdi-account-cancel
              </v-icon>
              <v-icon
                v-else
                style="cursor:pointer;"
                color="success darken-3"
                class="mr-2"
                @click="activeInactiveClients(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    components: {
      CampoClient: () => import('./CampoClient'),
      CampoClientEmailFacturacion: () => import('./CampoClientEmailFacturacion'),
    },
    name: 'ShowClients',
    data () {
      return {
        search: '',
        copyAlert: false,
        msgtokencopiado: 'Token Copiado',
        msgAlertStatus: 'Estado de cliente modificado',
        alertStatus: false,
        headers: [
          { text: 'Compania', value: 'IdCompania' },
          { text: 'Cliente', value: 'NombreCompania' },
          { text: 'Nit', value: 'NitCliente' },
          { text: 'Ambiente', value: 'Estado' },
          { text: 'Estado', value: 'Activo' },
          { text: 'Token Enterprise', align: 'center', sortable: false, value: 'TokenEnterprise' },
          { text: 'Token Password', align: 'center', sortable: false, value: 'TokenPassword' },
          { text: 'Url Proveedor Tecnológico', value: 'Url' },
          { text: 'Url Api Gestión', value: 'UrlApiGestion' },
          { text: 'Datos Fabricante', value: 'Nit' , align: 'center', sortable: false,},
          { text: 'Correo Electrónico', value: 'EmailDefaultFE' , align: 'center', sortable: false,},
          { text: 'Fecha Creación   ', value: 'FechaCreacion' },
          { text: 'Fecha Actualización', value: 'FechaActualizacion' },
          { text: 'Activar / Inactivar', value: 'ActivarInactivar' },
        ],
      }
    },
    computed: {
      details () {
        return this.$store.state.clients.listClients.Mensaje
      },
      updateResponseClient () {
        return this.$store.state.clients.updateStatusResponse
      },

    },
    watch: {
      updateResponseClient () {
        if (this.updateResponseClient > 0) {
          this.generateAlertStatus()
          this.$store.dispatch('clients/setUpdateResponseClient', 0)
        }
      },
    },
    created () {
      this.getListClients()
    },
    methods: {
      generateAlertStatus () {
        this.alertStatus = true
        this.$store.dispatch('clients/getListClients')
        setTimeout(() => {
          this.alertStatus = false
        }, 3000)
      },
      onCopy (e) {
        this.generateAlertCopy()
      },
      generateAlertCopy () {
        this.copyAlert = true
        setTimeout(() => {
          this.copyAlert = false
        }, 2000)
      },
      async getListClients () {
        await this.$store.dispatch('clients/getListClients')
      },
      async activeInactiveClients (item) {
        await this.$store.dispatch('clients/activeInactiveClient', item)
      },

    },
  }
</script>

<style scoped>

</style>
